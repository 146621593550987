// src/components/CarouselSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const checkMarker = '✓'

const carouselItems = [
  {
    image: 'https://i.postimg.cc/fW9ZYxZN/Pec-a-Pec-as-Imagem-2.png',
    title: 'BUSCADOR PEÇA PEÇAS',
    logo: 'https://i.postimg.cc/CK3Xr09p/Pec-a-Pec-as-Logo.png',
    description: 'Marketplace de peças automotivas genuínas, vendidas por concessionárias e distribuidores autorizados.',
    features: [
      'Mais de 30 anos de experiência',
      '800 vendedores integrados',
      'Mais de 7MM de peças conhecidas',
      '20k usuários consultam peças conosco',
      'Mais de 300k pesquisas de peças encontradas todo mês'
    ],
    link: 'https://www.pecapecas.com.br',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/rmy0KQ7M/Garage-Hero-Imagem-2.png',
    title: 'ERP GARAGE HERO',
    logo: 'https://i.postimg.cc/Bb7tyFVX/Garage-Hero-Logo.png',
    description: 'Sistema de Gestão ERP focado em pequenas e médias empresas do setor automotivo.',
    features: [
      'Mais de 200 funcionalidades próprias para gestão de uma PME',
      'Cerca de 800 empresas confiam diariamente em nossa solução',
      'Mais de 60k OSs abertas com sucesso',
      'Diferenciais importantes para melhorar o relacionamento com cliente final'
    ],
    link: 'www.garagehero.com.br',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/gcLd318j/Ecommerce-Imagem-2.png',
    title: 'E-COMMERCE DE PEÇAS',
    logo: 'https://i.postimg.cc/qRxXYmrQ/Ecommerce-Logo.png',
    description: 'Criação de e-commerce de peças próprio para concessionárias e distribuidores.',
    features: [
      'Gerenciar anúncios de forma automática, integrado ao estoque',
      'Fornecemos o catálogo de peças com fotos, descrição e informações',
      'Conveniência aos compradores que buscam peças através da placa do veículo',
      'Mais de 60 sites administrados de marcas como FIAT, JEEP, GM, Peugeot, Citroen, Mercedes, BMW, BMW Motorrad, Jaguar, Land Rover, Triumph e outras.'
    ],
    link: 'Sites exemplo: Solicite para nosso time de atendimento',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/MG73WpJg/Mercado-Livre-Imagem-2.png',
    title: 'INTEGRAÇÃO MERCADO LIVRE',
    logo: 'https://i.postimg.cc/JzdJbr5X/Mercado-Livre-Logo.png',
    description: 'Integramos o seu estoque no Mercado Livre.',
    features: [
      'Gerenciar anúncios de forma automática, integrado ao estoque com atualização online de preço e quantidade',
      'Fornecemos o catálogo de peças com fotos, descrição e informações',
      'Conveniência aos compradores que buscam peças através da placa do veículo',
      'Imagens com marca d’água, impedindo uso indevido de seus dados por outras empresas concorrentes'
    ],
    link: 'Solicite uma demonstração',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/Z5VLb7fF/Garagem-Virtual-Imagem-2.png',
    title: 'MOTOR ID (GARAGEM VIRTUAL)',
    logo: 'https://i.postimg.cc/WzQCFLfc/Garagem-Virtual-Logo.png',
    description: 'Página web para clientes (proprietários) controlarem seus veículos e acompanharem as principais informações',
    features: [
      'Compra de peças e acessórios',
      'Agendamento de serviços',
      'Acompanhamento de serviço em andamento',
      'Histórico de serviços já realizados',
      'Cadastro de veículos (mais de um)'
    ],
    link: 'Solicite uma demonstração',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/d0KPXRFJ/maquininha-Copia.png',
    title: 'MEIO DE PAGAMENTO',
    logo: 'https://i.postimg.cc/Bb1wG7ZY/Meio-de-Pagamento-Logo.png',
    description: 'Solução de recebimento através de máquina POS e integração de gateway para transações online.',
    features: [
      'Recebimento via cartão de débito, crédito e PIX',
      'Crédito do valor transacionado em conta corrente em até 32 dias, independente do prazo de pagamento do cliente (parcelado ou à vista)',
      'Possibilita a antecipação do valor à receber sob taxa única',
      'Solução de conciliação bancária, ajudando na identificação dos valores recebidos em conta',
    ],
    link: 'Solicite uma demonstração',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  },
  {
    image: 'https://i.postimg.cc/Y2QW8BX0/CRM-Imagem.png',
    title: 'CRM - RELACIONAMENTO COM CLIENTE',
    logo: 'https://i.postimg.cc/wT67Vyy4/CRM-Logo.png',
    description: 'Integramos o seu estoque no Mercado Livre',
    features: [
      'Gerenciar anúncios de forma automática, integrado ao estoque com atualização online de preço e quantidade',
      'Fornecemos o catálogo de peças com fotos, descrição e informações',
      'Imagens com marca d’água, impedindo uso indevido de seus dados por outras empresas concorrentes',
      'Crie gatilhos de conversa e agendamento de tarefas durante a etapa de relacionamento com o cliente',
      'Gerenciamento e acompanhamento do funil de vendas e conversão',
    ],
    link: 'Solicite uma demonstração',
    apiInfo: 'Documentação API: Solicite para nosso time comercial'
  }
];

const CarouselSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          CONHEÇA NOSSAS <span className="bg-custom-red rounded-[8px] text-white px-2">SOLUÇÕES</span>
        </h2>
        <Carousel 
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          arrows={true}
          className="max-w-5xl mx-auto"
        >
          {carouselItems.map((item, index) => (
            <div key={index} className="flex flex-col md:flex-row items-center text-center md:text-left px-4 gap-8">
              <div className="md:w-1/2">
                <img src={item.image} alt={item.title} className="w-full max-h-52 object-contain rounded-lg" />
              </div>
              <div className="md:w-1/2">
                <div className="flex items-center mb-2">
                  <img src={item.logo} alt={`${item.title} logo`} className="w-12 h-auto mr-2" />
                  <h2 className="text-2xl font-bold">{item.title}</h2>
                </div>
                <p className="text-md text-gray-700 mb-4 text-left">{item.description}</p>
                <ul className="list-disc list-inside mb-4 text-left no-bullets">
                  {item.features.map((feature, i) => (
                    <li key={i}> {checkMarker} {feature}</li>
                  ))}
                </ul>
                <a href={item.link} className="text-blue-600 underline mb-2">{item.link}</a>
                <p className="text-sm">{item.apiInfo}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default CarouselSection;