// src/components/HighlightsSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const highlights = [
  {
    image: 'https://i.postimg.cc/DwGQSf3n/VR.png',
    title: 'LANÇAMENTO DE VEÍCULOS EM VR',
    description: 'Temos um case de lançamento de um veículo elétrico usando realidade aumentada. Conheça essa tecnologia <strong><a href=" https://novosite.motorhero.com.br/new/">aqui</a>.</strong>'
  },
  {
    image: 'https://i.postimg.cc/qMPyHfhY/Inteligencia-Artificial.png',
    title: 'INTELIGÊNCIA ARTIFICIAL',
    description: 'Projetos com uso de AI para gerar eficiência em atividades operacionais e que exijam um grande volume de dados, como catálogo de peças e aplicação em veículos.'
  },
  {
    image: 'https://i.postimg.cc/ncbB6bQW/Chatbot.png',
    title: 'CHAT BOT INTERATIVO',
    description: 'Após o teste drive do veículo, o interessado recebe um relatório com as principais informações de trajeto, aceleração, consumo médio...'
  },
  {
    image: 'https://i.postimg.cc/K8JXfV1B/Showroom-Digital.png',
    title: 'SHOWROOM DIGITAL',
    description: 'Já imaginou oferecer uma página para o seu cliente realizar todas as simulações de cores, acabamentos e acessórios?'
  },
  {
    image: 'https://i.postimg.cc/bwYWZTmJ/Test-Drive.png',
    title: 'TEST DRIVE COM TELEMETRIA',
    description: 'Após o teste drive do veículo, o interessado recebe um relatório com as principais informações de trajeto, aceleração, consumo médio...'
  }
];

const HighlightsSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto custom-carousel">
        <h2 className="text-3xl font-bold text-center mb-8">
          PROJETOS E CASES DE <span className="bg-custom-red rounded-[8px] text-white px-2">SUCESSO</span>
        </h2>
        <Carousel 
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          arrows={true}
          className="max-w-5xl mx-auto"
        >
          {highlights.map((highlight, index) => (
            <div key={index} className="flex flex-col items-center text-center px-4">
              <img src={highlight.image} alt={highlight.title} className=" mb-4" />
              <h3 className="text-xl font-bold mb-2">{highlight.title}</h3>
              <p className="text-sm text-gray-700" dangerouslySetInnerHTML={{ __html: highlight.description }}></p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default HighlightsSection;
