// src/pages/PricingPage.js
import React from 'react';
import PricingPlans from '../components/PricingPlans';

const PricingPage = () => {
  return (
    <div>
      <PricingPlans />
    </div>
  );
};

export default PricingPage;
