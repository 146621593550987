// src/components/CompaniesProfileSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const carouselItems = [
  {
    image: 'https://i.postimg.cc/MKm55Kr5/1-Montadoras.png',
    title: 'Montadoras e fabricantes de peças',
  },
  {
    image: 'https://i.postimg.cc/4yTvvr6j/2-Seguradoras.png',
    title: 'Seguradoras e empresas de orçamentação',
  },
  {
    image: 'https://i.postimg.cc/zfbTsJp9/3-Concessionarias.jpg',
    title: 'Concessionárias e Importadores',
  },
  {
    image: 'https://i.postimg.cc/FFZkndNY/4-Oficinas.png',
    title: 'Oficinas Mecânicas & Auto Centers',
  },
  {
    image: 'https://i.postimg.cc/PqP74qsn/5-Autopecas.png',
    title: 'Auto Peças & Acessórios',
  },
  {
    image: 'https://i.postimg.cc/NF7CM9Tm/6-Outras.png',
    title: 'Outras empresas do setor automotivo',
  }
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const CompaniesProfileSection = () => {
  return (
    <section className="bg-gray-200 py-10">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          PERFIL DE EMPRESAS QUE <span className="bg-custom-red rounded-[8px] text-white px-2">ATENDEMOS</span>
        </h2>
        <Carousel 
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
        >
          {carouselItems.map((item, index) => (
            <div key={index} className="p-4">
              <div className="p-6 rounded-lg flex flex-col">
                <img src={item.image} alt={item.title} className="rounded-lg mb-4" />
                <h3 className="text-lg font-semibold text-center">{item.title}</h3>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default CompaniesProfileSection;
