// src/components/FlashComponent.js
import React from 'react';

const FlashComponent = () => {
  return (
    <div className="bg-black text-white text-center p-3 text-sm fixed w-full top-0 z-10">
      <span className='text-base font-bold'>TOP 1 AUTOTECH IN BRASIL 2023 | 100 OPEN STARTUPS</span>
    </div>
  );
};

export default FlashComponent;
