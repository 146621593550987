// src/pages/HelpCenter.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { categories } from '../interfaces/categories';

Modal.setAppElement('#root');

const HelpCenter = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        console.log('Fetching articles from Firestore...');
        const querySnapshot = await getDocs(collection(db, 'articles'));
        const fetchedArticles = querySnapshot.docs.map(doc => doc.data());
        console.log('Fetched Articles:', fetchedArticles); 
        setArticles(fetchedArticles);
      } catch (error) {
        console.error('Error fetching articles: ', error);
        setError('Error fetching articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const filteredArticles = Array.isArray(articles) ? 
    (selectedCategory === 'All' ? articles : articles.filter(article => article.category === selectedCategory)) : [];

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  return (
    <section className="bg-gray-100 py-20">
      <div className="container mx-auto flex flex-col md:flex-row items-start gap-2">
        <aside className="w-full md:w-1/4 bg-white p-6 shadow-md mb-8 md:mb-0">
          <h2 className="text-2xl font-bold mb-4 bg-[#0BA1D6] rounded-[8px] text-white p-1">Centro de Ajuda</h2>
          <nav>
            <ul className="space-y-2 ">
              {categories && categories.map((category, index) => (
                <li
                  key={index}
                  className={`text-gray-700 hover:text-gray-900 cursor-pointer ${selectedCategory === category ? 'font-bold' : ''}`}
                  onClick={() => {
                    setSelectedCategory(category);
                  }}
                >
                  {category}
                </li>
              ))}
            </ul>
          </nav>
        </aside>
        <main className="w-full md:w-3/4 p-6 bg-white shadow-md">
          <h2 className="text-3xl font-bold mb-4 bg-[#0BA1D6] rounded-[8px] text-white p-1">Artigos de Ajuda</h2>
          {loading ? (
            <p>Carregando Artigos...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <div className="space-y-4">
              {Array.isArray(filteredArticles) && filteredArticles.length > 0 ? (
                filteredArticles.map((article, index) => (
                  <div key={index} className="p-6 bg-gray-100 rounded shadow">
                    <h3 className="text-xl font-semibold">{article.title}</h3>
                    <p className="text-gray-600">Categoria: {article.category}</p>
                    <button onClick={() => openModal(article.content)} className="text-blue-600 hover:underline">Ler mais</button>
                  </div>
                ))
              ) : (
                <p>Nenhum artigo encontrado </p>
              )}
            </div>
          )}
        </main>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Article Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="max-h-screen overflow-y-auto p-4">
          <h2 className="text-2xl font-bold mb-4">Article Details</h2>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
          <button onClick={closeModal} className="mt-4 bg-blue-600 text-white px-4 py-2 rounded">Fechar</button>
        </div>
      </Modal>
    </section>
  );
};

export default HelpCenter;
