// src/components/PricingCard.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PricingCard = ({ title, description, features, billingCycle, monthlyPrice, annualPrice }) => {
  const discountedMonthlyPrice = (annualPrice / 12).toFixed(2).replace('.', ',');
  const calculateSavings = (monthlyPrice, annualPrice) => {
    const annualCostMonthly = monthlyPrice * 12;
    return annualCostMonthly - annualPrice;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md relative">
      {billingCycle === 'annually' && (
        <div className="absolute top-0 left-0 bg-yellow-400 px-1 text-black rounded-tr-lg rounded-bl-lg">
          Você economiza {calculateSavings(monthlyPrice, annualPrice).toFixed(2).replace('.', ',')} reais
        </div>
      )}
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-4xl font-bold mb-2">
        R${billingCycle === 'monthly' ? monthlyPrice.toFixed(2).replace('.', ',') : discountedMonthlyPrice}
        {billingCycle === 'annually' && <span className="text-sm text-gray-500"> /mês</span>}
      </p>
      {billingCycle === 'annually' && (
        <>
          <p className="text-sm text-gray-500 line-through">R${monthlyPrice.toFixed(2).replace('.', ',')} /mês</p>
          <p className="text-sm text-gray-500">Total: R${annualPrice.toFixed(2).replace('.', ',')}</p>
        </>
      )}
      <p className="mb-4">{description}</p>
      <ul className="list-none space-y-2 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      <button className="bg-[#0BA1D6] text-white px-4 py-2 rounded">Escolher Plano</button>
      {title === 'Básico' && (
        <div className="mt-4 bg-blue-100 text-blue-600 p-2 rounded">
          15 dias grátis
        </div>
      )}
    </div>
  );
};

export default PricingCard;
