// src/components/BusinessPlansSection.js
import React from 'react';

const BusinessPlansSection = () => {
  return (
    <section className="py-10">
      <div className='flex justify-center'>
        <h2 className="text-3xl font-bold mb-4 md:inline flex flex-col justify-center">
          DESENVOLVIMENTO <span className="bg-custom-red rounded-[8px] text-white px-2 text-center">SOB MEDIDA</span>
        </h2>
      </div>
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <img 
            src="https://i.postimg.cc/kX1QZxgw/Planos-para-m-dias-e-grandes.jpg" 
            alt="Desenvolvimento Sob Medida" 
            className="mx-auto"
          />
        </div>
        <div className="w-full md:w-1/2">
          <ul className="text-left list-disc pl-5 space-y-2 no-bullets">
            <li>✓ Desenvolvemos <strong>projetos especiais</strong>, de acordo com a necessidade de cada cliente</li>
            <li>✓ Possibilidade de <strong>solução white-label</strong> com domínio, cores e especificações próprias do contratante</li>
            <li>✓ Sistemas para <strong>multiempresas</strong>, como franquias</li>
            <li>✓ <strong>Acesso segregado</strong> para administrador e lojista</li>
            <li>✓ Disponível acesso a banco de dados, BI e <strong>dashboards</strong></li>
            <li>✓ Entre outros benefícios</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BusinessPlansSection;
