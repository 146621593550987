// src/components/HeroSection.js
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

const HeroSection = () => {
  const [highlightedText, setHighlightedText] = useState("TECNOLOGIA");
  const words = ["TECNOLOGIA", "PROJETOS", "SISTEMAS"];
  const indexRef = useRef(0); // useRef para manter o valor do índice entre renders

  useEffect(() => {
    const interval = setInterval(() => {
      indexRef.current = (indexRef.current + 1) % words.length;
      setHighlightedText(words[indexRef.current]);
    }, 2500); // Trocar a cada 2,5 segundos

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [words.length]);

  return (
    <section className="bg-gray-100 py-10">
      <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4">
        <div className="lg:w-2/3 mb-10 lg:mb-0 text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-black mb-4">
            DESENVOLVIMENTO DE <span className='px-[0.1rem]'></span>
            <motion.span 
              className="bg-custom-red text-white px-2 rounded-[8px]"
              key={highlightedText}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
            >
              {highlightedText}
            </motion.span>
           <br /> PARA EMPRESAS DO SETOR <br />AUTOMOTIVO
          </h1>
          <p className="text-gray-700 mb-6 text-left">
            Conheça nosso portfólio de soluções e veja como <br /> podemos ajudar sua empresa a se destacar!
          </p>
          <div className="flex space-x-4">
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/Hn4jj5SH/Topo-Estrela.png" alt="Ícone de estrela" className="w-6 h-6 mr-2" />
            </div>
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/X74pmHJv/Topo-Seguran-a.png" alt="Ícone de segurança" className="w-6 h-6 mr-2" />
            </div>
            <div className="text-black flex items-center">
              <img src="https://i.postimg.cc/1Rp8WhyT/Topo-Rapidez.png" alt="Ícone de rapidez" className="w-6 h-6 mr-2" />
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 flex justify-center">
          <img src="https://i.postimg.cc/0jv515Qh/Carro-Topo.png" alt="Carro" className="w-full h-auto" />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
