// src/components/BenefitsSection.js
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const benefits = [
  {
    image: 'https://i.postimg.cc/vTrm9Khq/1-Clientes.png',
    title: 'Temos relacionamento direto com cerca de',
    highlight: '2 mil clientes',
    description: 'e beneficiamos cerca de 300 mil usuários'
  },
  {
    image: 'https://i.postimg.cc/YST2P7yD/2-Solu-es.png',
    title: 'Temos mais de 20 diferentes',
    highlight: 'soluções de TI',
    description: 'próprias entre ERP, CRM e marketplace'
  },
  {
    image: 'https://i.postimg.cc/Y262dJN8/3-Tech.png',
    title: 'Entendemos que através de tecnologia é possível',
    highlight: 'transformar e amadurecer',
    description: 'o setor automotivo'
  },
  {
    image: 'https://i.postimg.cc/T1fx83j0/4-Top.png',
    title: 'TOP 1: Fomos reconhecidos em 2023 como a principal',
    highlight: 'empresa de TI',
    description: 'do setor automotivo'
  },
  {
    image: 'https://i.postimg.cc/7hxFsQDQ/5-Acelera-o-escolher.png',
    title: 'Participamos dos principais programas de',
    highlight: 'aceleração de startups',
    description: 'do Brasil e da América Latina'
  }
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const BenefitsSection = () => {
  return (
    <section className="bg-white py-10">
      <div className="container mx-auto">
        <h2 className="md:text-3xl text-2xl font-bold text-center mb-8">
          NOSSOS PRINCIPAIS <span className="bg-custom-red rounded-[8px] text-white px-2">NÚMEROS E RESULTADOS</span>
        </h2>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          showDots={false}
          arrows={true}
          className="max-w-5xl mx-auto"
        >
          {benefits.map((benefit, index) => (
            <div key={index} className="p-4">
              <div className="p-6 rounded-lg flex justify-center flex-col items-center">
                <img src={benefit.image} alt={benefit.title} className="rounded-lg mb-4 object-cover max-w-12" />
                <h3 className="text-lg font-semibold text-center">{benefit.title} <strong>{benefit.highlight}</strong> {benefit.description}</h3>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default BenefitsSection;
