// src/components/Separator.js
import React from 'react';

const Separator = () => {
  return (
    <div className='max-w-[1280px] h-1 bg-slate-200 mx-auto mt-2'></div>
  );
};

export default Separator;
