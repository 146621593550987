// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import CarouselSection from './components/CarouselSection';
import HighlightsSection from './components/HighlightsSection';
import CompaniesProfileSection from './components/CompaniesProfileSection';
import TestimonialsSection from './components/TestimonialsSection';
import BenefitsSection from './components/BenefitsSection';
import PricingSection from './components/PricingSection';
import BusinessPlansSection from './components/BusinessPlansSection';
import NewsSection from './components/NewsSection';
import RepresentativeSection from './components/RepresentativeSection';
import SupportSection from './components/SupportSection';
import Footer from './components/Footer';
import FloatingWhatsAppButton from './components/FloatingWhatsAppButton';
import Separator from './components/Separetor';
import FlashComponent from './components/FlashComponent';
import PricingPage from './pages/PricingPage';
import HelpCenter from './pages/HelpCenter';
import CookieConsent from './components/CookieConsent'; // Importar o novo componente

function App() {

  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };


  return (
    <Router>
      <div className="App">
        <Header />
        <FlashComponent />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <CarouselSection />
              <Separator />
              <HighlightsSection />
              <CompaniesProfileSection />
              {/* <TestimonialsSection /> */}
              <BenefitsSection />
              <Separator />
              {/* <PricingSection /> */}
              {/* <Separator /> */}
              <BusinessPlansSection />
              <NewsSection />
              {/* <RepresentativeSection />
              <SupportSection /> */}
            </>
          } />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/help-center" element={<HelpCenter />} />
        </Routes>
        <Footer />
        <FloatingWhatsAppButton cookiesAccepted={cookiesAccepted} />
        <CookieConsent onAccept={handleAcceptCookies} />
      </div>
    </Router>
  );
}

export default App;
