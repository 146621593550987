// src/components/NewsSection.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './NewsSection.css';

const newsItems = [
  {
    image: 'https://i.postimg.cc/HxGmT8wb/image-10.png',
    title: 'SISTEMA PDV',
    date: '',
    description: 'Esta semana iniciamos a implantação do módulo de...',
    fullArticle: 'Este módulo facilita a gestão de vendas diretamente na oficina, permitindo realizar operações de venda de produtos e serviços, emissão de cupons fiscais, e integração com o controle de estoque e financeiro do sistemaAqui está o conteúdo completo do artigo sobre o Sistema PDV...'
  },
  {
    image: 'https://i.postimg.cc/02yTR0TL/Pngtree-nfs-file-document-icon-4233926-1.png',
    title: 'Emissão de Notas Fiscais (NF) e Notas Fiscais de Serviço (NFS)',
    date: '',
    description: 'Estas funcionalidades permitem que as oficinas gerenciem...',
    fullArticle: 'Estas funcionalidades permitem que as oficinas gerenciem suas obrigações fiscais de forma eficiente e integrada ao sistema. A emissão de NF é usada para registrar a venda de produtos, enquanto a NFS é usada para a prestação de serviços.'
  },
  {
    image: 'https://i.postimg.cc/HxSrS2DT/image-8.png',
    title: 'Área do cliente',
    date: '',
    description: 'Essa funcionalidade permite que os clientes da oficina acessem...',
    fullArticle: 'Essa funcionalidade permite que os clientes da oficina acessem informações sobre seus veículos, históricos de serviços, orçamentos, e status de reparos. A área do cliente melhora a comunicação entre a oficina e os clientes, proporcionando maior transparência e comodidade.'
  },
  {
    image: 'https://i.postimg.cc/LsQqDDM2/image-9.png',
    title: 'Calendário integrado',
    date: '',
    description: 'Esse recurso ajuda na gestão de agendamentos de serviços...',
    fullArticle: 'Esse recurso ajuda na gestão de agendamentos de serviços, permitindo que a oficina organize e visualize compromissos, agendamentos de manutenção e reparos de maneira eficiente. O calendário facilita a administração do fluxo de trabalho e a gestão de atividades realizadas pela oficina.'
  },
  {
    image: 'https://i.postimg.cc/V6T7b2st/image-11.png',
    title: 'Kanban',
    date: '',
    description: 'O kanban é uma ferramenta visual de gerenciamento de tarefas...',
    fullArticle: 'O kanban é uma ferramenta visual de gerenciamento de tarefas que permite organizar e acompanhar o progresso dos trabalhos na oficina de maneira eficiente. Com o kanban, é possível visualizar o status das ordens de serviço, identificar gargalos e melhorar a produtividade e a comunicação dentro da equipe.'
  },
  {
    image: 'https://i.postimg.cc/7LXB6VZk/modelos-para-cliente-1.png',
    title: 'Garage Card',
    date: '',
    description: 'Ele é um cartão físico oferecido pela oficina aos seus clientes...',
    fullArticle: 'Ele é um cartão físico oferecido pela oficina aos seus clientes, com o logo da oficina de um lado e um QR code no verso, que dá acesso à área do cliente do Garage Hero. Esse cartão serve como um cartão de visita e contém todo o histórico do veículo, facilitando o acesso a informações importantes de maneira prática e rápida.'
  },
  {
    image: 'https://i.postimg.cc/J4QzGCmB/etiqueta-62058.png',
    title: 'Funcionalidade de impressão de etiquetas',
    date: '',
    description: 'Especificamente para etiquetas de óleo que incluem QR codes...',
    fullArticle: 'Especificamente para etiquetas de óleo que incluem QR codes. Essa ferramenta é projetada para facilitar o acompanhamento e a gestão de serviços de troca de óleo, permitindo que informações importantes sejam acessadas de maneira rápida e eficiente pelos clientes através dos QR codes'
  },
  // Adicione mais itens se necessário
];
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const NewsSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  const openModal = (news) => {
    setSelectedNews(news);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedNews(null);
  };

  return (
    <section className="py-10 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          ACOMPANHE AS <span className="bg-custom-red rounded-[8px] text-white px-2">NOVIDADES!</span>
          <a href="https://www.instagram.com/motorherooficial/" target='_blank' rel="noreferrer" className='md:inline hidden'>
            <span className="float-right">
              <img src="https://i.postimg.cc/y8N5QKCy/Instagram.png" alt="Instagram" className="inline-block w-8 h-8"/>
            </span>
          </a>
        </h2>
        <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={3000} keyBoardControl={true} showDots={true}>
          {newsItems.map((news, index) => (
            <div key={index} className="p-4">
              <div className="p-6 rounded-lg cursor-pointer" onClick={() => openModal(news)}>
                <img src={news.image} alt={news.title} className="rounded-lg mb-4 w-full h-64 object-fill" />
                <h3 className="text-lg font-semibold">{news.title}</h3>
                <p className="text-sm text-gray-500">{news.date}</p>
                <p className="text-sm">{news.description}</p>
              </div>
            </div>
          ))}
        </Carousel>
        <div className="mt-8 flex justify-center md:hidden">
          <a href="https://www.instagram.com/motorherooficial/" target='_blank' rel="noreferrer" className="bg-custom-red text-white px-4 py-2 rounded flex flex-col justify-center items-center">
            Acesse nosso Instagram
            {/* <img src="https://i.postimg.cc/pdk5Z1Pg/Prancheta-4-c-pia-42.png" className="max-w-32 invert-1" alt="" /> */}
          </a>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Artigo Completo"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {selectedNews && (
          <div>
            <h2 className="text-2xl font-bold mb-4">{selectedNews.title}</h2>
            <p className="text-gray-500 mb-4">{selectedNews.date}</p>
            <div className="text-lg">
              {selectedNews.fullArticle}
            </div>
            <button onClick={closeModal} className="mt-4 bg-custom-red text-white px-4 py-2 rounded">Fechar</button>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default NewsSection;
