// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-300 py-1">
      <div className="container mx-auto flex flex-col md:flex-row gap-4 justify-between items-center text-black text-sm">
        <span>Todos os direitos reservados ®</span>
        <div className="flex items-center">
          <span>#wedriveyourtech</span>
          <img src="https://i.postimg.cc/L4NYpR0T/Motor-Hero-Logo.png" alt="Logo" className="h-28" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
