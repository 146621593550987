import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);

    // Dispara um evento personalizado
    window.dispatchEvent(new Event('cookieConsentAccepted'));
  };

  return (
    showBanner && (
      <div className="fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 flex flex-col md:flex-row items-center justify-center">
        <div className='flex items-center gap-7'>
          <p className="text-sm mb-2 md:mb-0">
            Este site usa cookies para melhorar sua experiência. Ao continuar navegando, você concorda com o uso de cookies.
          </p>
          <button
            onClick={handleAccept}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Aceitar
          </button>
        </div>
      </div>
    )
  );
};

export default CookieConsent;