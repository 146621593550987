// src/components/PricingPlans.js
import React, { useState } from 'react';
import PricingCard from './PricingCard';

const PricingPlans = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const plans = [
    {
      title: 'Inicial',
      monthlyPrice: 49.99,
      annualPrice: 479.90,
      description: 'Plano grátis para todos os usuários',
      features: [
        'Gerencie seus clientes, Compartilhe com seu cliente a evolução do serviço por WhatsApp.',
        'Cobre de seus clientes com link de pagamento e boleto. Receba direto pela ferramenta',
        'Faça pesquisa do veículo por placa.',
        'Integração com site Peça Peças: pesquise e encontre 7 milhões de peças automotivas para compra',
        'Ferramenta de gestão contas pagar receber e tesouraria caixa.',
      ],
    },
    {
      title: 'Básico',
      monthlyPrice: 139.99,
      annualPrice: 1343.90,
      description: 'Ideal para pequenas empresas.',
      features: [
        'Todas as funcionalidades do plano básico',
        'Múltiplos logins para seus funcionários.',
        'Disponibilizamos a opção de maquininha de cartão de crédito.',
        'Relatórios completos gerenciais',
        'Área do cliente, com histórico de revisões',
        'Calendário e lembretes.',
        'Nota fiscal de produto e serviço',
        'Kanban 4k para sua oficina.',
      ],
    },
    {
      title: 'Pro',
      monthlyPrice: 239.99,
      annualPrice: 2303.90,
      description: 'Funciona melhor para empresas empresariais.',
      features: [
        'Todas as funcionalidades dos planos básico e profissional',
        'Plano mais completo',
        'Pedido de impressora térmica para etiqueta de óleo',
        'Pedido de Garage Card',
        'Peça Crédito',
        'Até 10 usuários simultâneos',
      ],
    },
  ];

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold mb-6 bg-blue-500 text-white px-2 py-3 text-center">Planos de preços Motor Hero</h1>
      {/* <p className="text-center mb-6">Recursos do StartAI que ajudarão sua empresa a crescer mais rapidamente</p> */}
      <div className="flex justify-center items-center mb-10">
        <span>Mensal</span>
        <label className="switch mx-2">
          <input type="checkbox" onChange={() => setBillingCycle(billingCycle === 'monthly' ? 'annually' : 'monthly')} />
          <span className="slider round"></span>
        </label>
        <span>Anualmente (20% de desconto)</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {plans.map((plan, index) => (
          <PricingCard key={index} {...plan} billingCycle={billingCycle} />
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
